(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/oddset/selfservice-integration/assets/javascripts/parse-wager.js') >= 0) return;  svs.modules.push('/components/oddset/selfservice-integration/assets/javascripts/parse-wager.js');
"use strict";


const isSelfService = svs.utils.ns.isNamespace('svs.core.config.data.svsconfig.isSelfService') && svs.core.config.data.svsconfig.isSelfService;
const DESTINATION = 'Kambi';
const PRODUCT_ID = 50;
const checkIfAnyLiveBets = data => data.supplierData.selectionCouponDetails.outcomeDetails.some(outcome => outcome.liveBetting === true);
const calculateDisplayAmount = data => {
  const sum = data.supplierData.bets.reduce((accumulator, betObject) => accumulator + betObject.stake, 0);
  return "".concat((sum / 1000).toFixed(2)).replace('.', ',');
};
const calculateDisplayOdds = data => {
  if (data.bettingTypeName === 'SYSTEM') {
    return '';
  }
  const oddsSum = data.supplierData.couponRows.reduce((accumulator, couponRowObject) => accumulator + couponRowObject.odds, 0);
  return "".concat((oddsSum / 1000).toFixed(2)).replace('.', ',');
};
const moveAndDelete = (sourceObject, sourcePropertyName, destinationObject, destinationPropertyName) => {
  const destPropName = destinationPropertyName ? destinationPropertyName : sourcePropertyName;
  if (sourceObject && sourcePropertyName && destinationObject && destPropName) {
    destinationObject[destPropName] = sourceObject[sourcePropertyName];
    delete sourceObject[sourcePropertyName];
  }
};
const splitSingles = unProcessedData => {
  const betSelectionObjects = [];
  unProcessedData.supplierData.couponRows.forEach(couponRow => {
    const currentSingle = structuredClone(unProcessedData);
    const betsInfoForIndex = currentSingle.supplierData.bets.find(bet => bet.couponRowIndexes[0] === couponRow.index);
    const outcomeDetailsObject = currentSingle.supplierData.selectionCouponDetails.outcomeDetails.find(outcome => outcome.outcomeId === couponRow.outcomeId);
    currentSingle.supplierData.couponRows = [couponRow];
    currentSingle.supplierData.couponRows[0].index = 0;
    currentSingle.supplierData.bets = [betsInfoForIndex];
    currentSingle.supplierData.bets[0].couponRowIndexes = [0];
    currentSingle.supplierData.selectionCouponDetails.outcomeDetails = [outcomeDetailsObject];
    betSelectionObjects.push(currentSingle);
  });
  return betSelectionObjects;
};
const parseWager = unProcessedData => {
  if (!isSelfService) {
    throw new Error('Tried adding to cart while not in selfservice mode');
  }
  let betSelectionObjects = [];

  const data = structuredClone(unProcessedData);

  data.selectionCoupon.couponRows.forEach(couponRow => {
    couponRow.outcomeId = "".concat(couponRow.outcomeId);
  });
  data.selectionCouponDetails.outcomeDetails.forEach(outcomeDetail => {
    outcomeDetail.outcomeId = "".concat(outcomeDetail.outcomeId);
  });

  moveAndDelete(data, 'selectionCouponType', data, 'bettingTypeName');
  moveAndDelete(data, 'selectionCoupon', data, 'supplierData');
  moveAndDelete(data, 'locale', data.supplierData);
  moveAndDelete(data, 'expiryDate', data.supplierData);
  moveAndDelete(data, 'market', data.supplierData);
  moveAndDelete(data, 'timeZone', data.supplierData);
  moveAndDelete(data, 'selectionCouponDetails', data.supplierData);
  data.destination = DESTINATION;
  data.productId = PRODUCT_ID;
  if (unProcessedData.selectionCouponType === 'SINGLES' && unProcessedData.selectionCoupon.couponRows.length > 1) {
    betSelectionObjects = splitSingles(data);
  } else {
    betSelectionObjects.push(data);
  }

  betSelectionObjects.forEach(betSelectionObj => {
    betSelectionObj.includesLiveBet = checkIfAnyLiveBets(betSelectionObj);
    betSelectionObj.displayAmount = calculateDisplayAmount(betSelectionObj);
    betSelectionObj.displayOdds = calculateDisplayOdds(betSelectionObj);
    betSelectionObj.supplierData.selectionCouponDetails.outcomeDetails = [betSelectionObj.supplierData.selectionCouponDetails.outcomeDetails];
  });
  return betSelectionObjects;
};
setGlobal('svs.components.oddset.selfserviceIntegration', {
  parseWager
});

 })(window);